import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  MenuProps,
  Modal,
  Select,
  Spin,
  message
} from 'antd';
import PosLayout from '@/layouts/pos/pos.layout';
import {
  AccountBookOutlined,
  CreditCardOutlined,
  DeleteFilled,
  DownOutlined,
  MobileOutlined,
  PlusOutlined,
  ShopOutlined
} from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { get_location_details, get_location_list } from '@/services/locations/queries';
import {
  get_units_list,
  prices_by_groupId,
  get_unexpired_lots_details_bylocationId_productId,
  get_price_groups_by_location,
  get_product_details,
  get_product_list_ids,
  get_lots_details_bylocationId_multipleProductIds,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { get_invoices_details, get_invoices_list } from '@/services/settings/queries';
import {
  get_customer_details,
  get_customer_list,
  get_customer_list_route,
  get_user_details
} from '@/services/users/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { getUserData } from '@/utils/auth.utils';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomersDB from '@/store/localstorage/CustomerDB';
import {
  find_default_pos_invoice,
  find_invoice_due_limit,
  find_locationId_preference,
  find_shipping_cost
} from '@/store/localstorage/preferences';
import { IUSerlocation } from '@/services/routes/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import useDebounce from '@/hooks/useDebounce';
import { ICustomer, IUserCreateAddressRequest } from '@/services/users/types';
import {
  ICreatePriceGroup,
  ILotDetails,
  IProductDetails,
  IProductType,
  IUnits
} from '@/services/products/types';
import { ILocations } from '@/services/locations/types';
//import ProductSearchForPos from '@/components/Common/ProductSearch2/ProductSearchForPos';
import { IAddPayment, ILines, ISellDiscount, ISellFromServer } from '@/services/sell/types';
import ProductSearchForPos from '@/components/Common/ProductSearch2/ProductSearchForPos';
import {
  create_sell_mutation,
  create_sell_payment,
  create_sell_payment_mutation
} from '@/services/sell/mutations';
import moment from 'moment';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { QRCodeSVG } from 'qrcode.react';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import logo from '@/layouts/logo/freshktmlogo.png';
import { get_payment_status_by_prn, get_qr_for_pos } from '@/services/payments/queries';
import { create_fonepay_mutation } from '@/services/payments/mutations';
import ReusableCustomerCreate from '@/components/Common/CustomModal/ReusableCustomerCreate';
import { on } from 'events';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { getSellPrintData } from '@/components/Common/InvoicePrint/SellInvoice/services';
import { ISellInvoice } from '@/services/invoice/types';
import BillPrint from '@/components/Common/InvoicePrint/BillPrint/BillPrint';
import { PosSessionProvider } from '@/layouts/pos/posSession.context';
import { get_session_info } from '@/services/pos-session/queries';
import { create_users_address_mutation } from '@/services/users/mutations';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import NotifySellPriceModal from '@/components/Common/NotifySellPriceModal';
import { configFonepaySocket } from '@/utils/websocket.utils';
import { IFonepayData, IFonepayTransactionStatus } from '@/services/web-sockets/types';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import { check_sell_duplicate, get_sell_discount } from '@/services/sell/queries';
import roundNumber from '@/utils/round.utils';
import { SellPaymentType } from '@/services/pos-session/enums';
import useSingleClick from '@/hooks/useSingleClick';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import getErrorMessage from '@/utils/getError';
import { Link } from 'react-router-dom';
import { getFonePayRemark } from './services';
import isAxiosError from '@/utils/isAxiosError';
import ErrorModalWithLog from '@/components/Common/ErrorModal/ErrorModalWithLog';

const { Option } = Select;

type ICheckDuplicate =
  | { payType: 'card' | 'cash' | 'credit' }
  | { payType: 'fonepay'; prn?: string };

type IUnitPriceFilled = { isFilled: boolean; index: number };

const POSCreateV2 = () => {
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [fonepaySocket, setFonepaySocket] = useState<WebSocket>();
  const [isSessionStart, setIsSessionStart] = useState<boolean>(false);
  const [hasRule, setHasRule] = useState<boolean>(true);
  const [productDetails, setProductDetails] = useState();
  const [locationInfo, setLocationInfo] = useState<ILocations>();
  const [addRestricion, setAddRestricion] = useState(false);
  const loggedInUser = getUserData();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [selectValue, setselectValue] = useState<number>();
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [isAddCostumerModalOpen, setIsAddCostumerModalOpen] = useState<boolean>(false);
  const [isAddTenderModalOpen, setIsAddTenderModalOpen] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] = useState<ICustomer>(Object);
  const [address, setAddress] = useState<IUSerlocation[]>([]);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [searchValueUser, setSearchValueUser] = useState<string>('');
  const [userSearch, setUserSearch] = useState<ICustomer[]>([]);
  const debouncedSearchValueUser = useDebounce(searchValueUser, 500);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  // const [productList, setProductList] = useState<IProductDetails[]>([]);
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const [productList, setProductList] = useState<IProductDetails[]>([]);
  const autofocusRef: React.Ref<HTMLInputElement> = useRef(null);
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const [payType, setPayType] = useState<string>('');
  const [pricegroupDropdown, setpricegroupDropdown] = useState<ICreatePriceGroup[]>([]);
  const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  const [totalLots, settotalLots] = useState<ILotDetails[]>([]);
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const [qrValue, setQrValue] = useState<{ qr: string; prn: string }>({ qr: '', prn: '' });
  const [Values, setValues] = useState<any>({});
  const [CopyValues, setCopyValues] = useState<any>({});
  const [billData, setBillData] = useState<ISellInvoice>(Object);
  const [openModalForBillPrint, setOpenModalForBillPrint] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [tenderAmount, setTenderAmount] = useState<number>(0);
  const [tenderReturnAmount, setTenderReturnAmount] = useState<number>(0);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [notifyModalData, setNotifyModalData] = useState<string[]>([]);
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState<boolean>(false);

  const QuantityRef = useRef<Array<HTMLInputElement | null>>([]);
  const isForcedPayment = useRef(false);
  const hasApiBeenCalled = useRef(false);

  const [alreadyCreatedSell, setAlreadyCreatedSell] = useState<ISellFromServer>();
  const [openAskingForPrintModal, setOpenAskingForPrintModal] = useState(false);

  useEffect(() => {
    fetchLocation();
    initializeUnits();
  }, []);

  const [isUnitPriceFilled, setIsUnitPriceFilled] = useState<boolean[]>([]);

  function onUnitPriceFilled({ isFilled, index }: IUnitPriceFilled) {
    const newIsUnitPriceFilled = [...isUnitPriceFilled];
    newIsUnitPriceFilled[index] = isFilled;
    setIsUnitPriceFilled(newIsUnitPriceFilled);
  }

  function removeFromUnitPrice(removedIndex: number) {
    const newIsUnitPriceFilled = [...isUnitPriceFilled];
    newIsUnitPriceFilled.splice(removedIndex, 1);
    setIsUnitPriceFilled(newIsUnitPriceFilled);
  }

  const initializeFonepaySocket = (url: string) => {
    const fonepayConfigSocket = configFonepaySocket(url);
    setFonepaySocket(fonepayConfigSocket);
  };

  useEffect(() => {
    const handleOpen = () => {
      console.log('Socket Id --->', fonepaySocket);
    };

    const handleMessage = async (data: MessageEvent) => {
      try {
        if (isForcedPayment.current || hasApiBeenCalled.current) return;
        const jsonData = JSON.parse(data.data) as IFonepayData;

        if (typeof jsonData.transactionStatus === 'string') {
          const jsonStatus = JSON.parse(jsonData.transactionStatus) as IFonepayTransactionStatus;
          if (jsonStatus.paymentSuccess === true) {
            hasApiBeenCalled.current = true;
            setShowConfirmPaymentModal(false);
            setIsQRModalOpen(false);
            await handleCheckPaymentClick();
            hasApiBeenCalled.current = false;
          }
        }
      } catch (e) {
        message.error('Unexpected error occurred. Please use check payment button.');
      }
    };

    fonepaySocket?.addEventListener('open', handleOpen);
    fonepaySocket?.addEventListener('message', handleMessage);

    return () => {
      fonepaySocket?.removeEventListener('open', handleOpen);
      fonepaySocket?.removeEventListener('message', handleMessage);
      fonepaySocket?.close();
    };
  }, [fonepaySocket]);

  const defaultBillPrint = find_default_pos_invoice();

  if (!defaultBillPrint) {
    message.error('Default Bill Print not set in global preferences!');
  }

  const userIdWatcher = Form.useWatch('userId', form);
  const currentActiveKeyWatcher = Form.useWatch('currentActiveKey', form);

  const addUser = async (userId: string) => {
    let user = userSearch.find((user) => user.id === parseInt(userId));

    // console.log('user1 values', user);

    if (user) {
      return;
    }
    user = await CustomersDB.getCustomer(parseInt(userId));
    if (user) {
      setUserSearch([...userSearch, user]);
    }
  };
  const addProduct = async (productId: number) => {
    let product = productSearch.find((product) => product.id === productId);
    if (product) {
      return;
    }
    product = await ProductsDB.getProductDetails(productId);

    return product;
  };

  const addProducts = async (productIds: number[]) => {
    const productToBeAdded: IProductDetails[] = [];
    for (const productId of productIds) {
      if (productId) {
        const product = await addProduct(productId);
        if (product) {
          productToBeAdded.push(product);
        }
      }
    }
    setTimeout(() => {
      // console.log('productToBeAdded', [...productSearch, ...productToBeAdded]);
      setProductSearch([...productSearch, ...productToBeAdded]);
    }, 50);
  };

  useEffect(() => {
    // console.log('userIdWatcher', userIdWatcher);
    if (userIdWatcher) {
      addUser(userIdWatcher);
    }
  }, [userIdWatcher]);

  useEffect(() => {
    if (!isSessionStart) {
      form.resetFields();
      form.resetFields(['lines']);
    }
  }, [isSessionStart]);

  const onQueueChange = async (queueNumber: number) => {
    setIsloading(true);
    const lines = form.getFieldValue('lines');

    const userId = form.getFieldValue('userId');
    if (userId) {
      onUserChange(userId);
    }
    // console.log('linesWatcher', lines);
    await addProducts(lines.map((line: any) => line?.productId));
    await fetchAllLots(lines.map((line: any) => line?.productId));

    for (const [index, line] of lines.entries()) {
      if (line.productId) {
        await onPriceandQuantityChange(index);
      }
    }

    setIsloading(false);
  };
  useEffect(() => {
    // console.log('currentActiveKeyWatcher', currentActiveKeyWatcher);
    if (locationInfo?.id && currentActiveKeyWatcher) {
      onQueueChange(currentActiveKeyWatcher);
    }
  }, [currentActiveKeyWatcher]);

  useEffect(() => {
    searchUser(debouncedSearchValueUser);
  }, [debouncedSearchValueUser]);

  useBeforeUnloadListener();

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const initializePriceGroup = async (locationId: number) => {
    const response = await get_price_groups_by_location(locationId);
    const defaultResponse = await get_price_groups_by_location('');
    if (response.data) {
      const currPriceGroups = [...response.data, ...defaultResponse.data];
      setpricegroupDropdown([...response.data, ...defaultResponse.data]);

      if (currPriceGroups.length == 0) {
        form.setFieldValue(['lines', 0, 'unitPrice'], 0);
        form.setFieldValue(['lines', 0, 'priceGroupId'], null);
        onUnitPriceFilled({ isFilled: false, index: 0 });
      }
    }
  };

  const fetchLocation = async () => {
    const preferenceLocationId = find_locationId_preference();
    try {
      if (typeof preferenceLocationId === 'number') {
        await checkAccountRule(preferenceLocationId);
        const response = await LocationsDB.getLocation(preferenceLocationId);
        if (!response) {
          message.info('Cannot find any location with that name in cache, searching in server...');
          const allLocation = await get_location_list(0, 100);
          if (allLocation.data.results.length == 0) {
            message.error('Cannot find any location with that name in server!');
            setLocationInfo(undefined);
            setAddRestricion(false);
          } else {
            LocationsDB.addLocations(allLocation.data.results);
            const locationDetails = allLocation.data.results.find(
              (value) => value.id === preferenceLocationId
            );
            setLocationInfo(locationDetails);

            const isDCLocation = locationDetails?.type === 'DC';
            setAddRestricion(!isDCLocation);
            if (locationDetails) initializePriceGroup(locationDetails?.id);
          }
        } else {
          setLocationInfo(response);
          initializePriceGroup(response.id);

          const isDCLocation = response?.type === 'DC';
          setAddRestricion(!isDCLocation);
          return response;
        }
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.SELL_CREATE)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.SELL_CREATE}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
    }
  };

  useQuery(['customerList'], async () => {
    setIsloading(true);
    const value = '';
    const response = await get_customer_list(0, 10, value);
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
      setSkip(0);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) setIsMore(false);
      else setIsMore(true);
      setSkip(10);
    }
    setIsloading(false);
  });

  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(response.data);
    return response;
  });

  useQuery(['sessionInfo'], async () => await fetchSession());

  useEffect(() => {
    socket?.on('connect', async () => {
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(lines.map((value: ILines) => value.productId));
      await fetchLotsOnProductChange([...productIds], 'lotsUpdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationInfo?.id) {
          settotalLots((prev) => {
            return prev.map((a) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        // Get Products and filter by Location
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationInfo) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationInfo.id &&
              Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnProductChange(updatedProductIds, 'lotsUpdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected, locationInfo]);

  const fetchSession = async () => {
    const response = await get_session_info();
    if (response) {
      setIsSessionStart(true);
    } else {
      setIsSessionStart(false);
    }
  };

  const fetchAllLots = async (productIds: number[]) => {
    if (!locationInfo?.id) {
      throw {
        name: 'Location Error',
        message: 'Please add Location in Preferences!'
      };
    }
    // console.log('productIds in fetchAllLots', productIds);
    productIds = productIds.filter((i) => i != null);
    if (productIds.length > 0) {
      const response = await get_lots_details_bylocationId_multipleProductIds(
        productIds,
        locationInfo.id
      );
      // console.log('response from fetchAllLots', response);
      settotalLots(response);
    }
    // console.log('lots', totalLots);
  };
  const searchUser = async (value: string) => {
    let response;
    try {
      response = await get_customer_list(0, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
      setSkip(0);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) setIsMore(false);
      else setIsMore(true);
      setSkip(10);
    }
  };

  const searchMoreUser = async (value: string) => {
    let response;
    try {
      const routeId = form.getFieldValue('routeId');
      if (routeId != '' && routeId != undefined) {
        response = await get_customer_list_route(skip, 10, value, routeId);
      } else {
        response = await get_customer_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more customer with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setUserSearch([...userSearch, ...response.data.results]);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const createAddress = async (userId: number) => {
    const defaultLocation = await find_locationId_preference();
    let LocationDetails: any;
    if (typeof defaultLocation === 'number') {
      LocationDetails = await LocationsDB.getLocation(defaultLocation);
      if (!LocationDetails) {
        LocationDetails = await get_location_details(defaultLocation);
        await LocationsDB.addLocations([LocationDetails]);
      }
    }
    const values: IUserCreateAddressRequest = {
      userId: userId,
      addressLine1: LocationDetails.name
    };
    return await updateAddressMutation.mutateAsync(values);
  };

  const updateAddressMutation = useMutation(create_users_address_mutation, {
    onError: () => {
      setIsloading(false);
    },
    onSuccess: async () => {
      message.success(`Address Created Successfully!`, 5);
      setIsloading(false);
    }
  });

  const onUserChange = async (id: number) => {
    setIsloading(true);
    let selectedUser = await CustomersDB.getCustomer(id);

    let userDetails; //for address
    if (!selectedUser) {
      const response = await get_customer_details(id);
      // console.log(response);
      if (response?.user && response?.customer) {
        selectedUser = response.customer;
        CustomersDB.addCustomers([{ ...response.customer, user: response?.user?.user }]);
        userDetails = response.user;
        // console.log('userDetails', userDetails);
      }
    }

    setSelectedCustomer(selectedUser);

    if (!userDetails) {
      if (selectedUser.userId) userDetails = await get_user_details(selectedUser.userId);
      // console.log('userDetails', userDetails);
    }
    if (userDetails) {
      if (userDetails.addresses.length > 0) {
        form.setFieldValue('addressId', userDetails.addresses[0].id);
      } else {
        // create address
        if (selectedUser.userId) {
          try {
            const address = await createAddress(selectedUser.userId);
            userDetails.addresses.push(address.data as any);
            form.setFieldValue('addressId', address.data.id);
          } catch (e) {
            message.error('Address creation unsuccessful! Please reselect again!');
          }
        } else {
          CustomErrorModal({ message: "Selected customer's address is not set!" });
        }
      }
      setCustomerDetails(selectedUser);
      setAddress(userDetails.addresses);
    }

    // console.log('useDetailsfrom state', userDetails);
    const scoreDetails = JSON.parse(selectedUser.scoreCard);
    if (scoreDetails) {
      const diff = scoreDetails?.numberOfSales - scoreDetails?.numberOfPayments;
      const limitdiff = find_invoice_due_limit();
      if (diff > limitdiff) {
        CustomErrorModal({
          message: `This customer has pending payments for ${diff} sales greater than limit.`
        });
        form.setFieldValue('userId', null);
        setTimeout(() => {
          setFormValues(form.getFieldsValue());
        }, 100);
        setIsloading(false);
        return;
      }
    }

    // Find Discount
    await getAllDiscounts();

    setIsloading(false);
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') {
      setShowConfirmPaymentModal(true);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Force Payment'
    }
  ];

  const getAllDiscounts = async (topOffers?: ISellDiscount[]) => {
    const lines = form.getFieldValue(['lines']);

    const customerId = form.getFieldValue(['userId']);
    if (locationInfo) {
      const linesWithQuantity = lines
        .filter((line: any) => line?.productId)
        .map((line: any) => {
          return {
            ...line,
            quantity: line.quantity || 0,
            discount: parseFloat(line.discount) || 0,
            misc: line.misc || 0,
            vat: parseFloat(line.vat) || 0
          };
        });
      if (linesWithQuantity.length > 0) {
        let topOffer: ISellDiscount[] = [];
        if (topOffers) {
          topOffer = topOffers;
        } else {
          topOffer = await get_sell_discount(locationInfo.id, customerId, linesWithQuantity);
        }
        if (topOffer) {
          // console.log('topOffer', topOffer);
          for (let i = 0; i < lines.length; i++) {
            if (lines[i]?.productId) {
              const discPer = topOffer.find(
                (val) => val.productId === lines[i].productId
              )?.discountPercent;
              form.setFieldValue(['lines', i, 'discountPer'], discPer);
              onPriceandQuantityChange(i);
            }
          }
        } else {
          message.error('Offer not found.');
        }
      }
    } else {
      message.error('Location not found.');
    }
  };

  let removeLine: (key: number | number[]) => void | null;

  const onProductChange = async (productId: number, fieldName: number) => {
    setIsloading(true);
    try {
      const lines = form.getFieldValue(['lines']);
      // console.log('lines', lines);
      let alreadyExist = false;
      for (let i = 0; i < lines.length - 1; i++) {
        if (lines[i].productId === productId && fieldName !== i) {
          alreadyExist = true;
          break;
        }
      }

      if (alreadyExist) {
        form.setFieldValue(['lines', fieldName, 'productId'], null);
        form.setFieldValue(['lines', fieldName, 'unitId'], null);
        form.setFieldValue(['lines', fieldName, 'lotId'], null);
        form.setFieldValue(['lines', fieldName, 'hsCode'], null);
        form.setFieldValue(['lines', fieldName, 'quantity'], null);
        form.setFieldValue(['lines', fieldName, 'priceGroupId'], null);
        form.setFieldValue(['lines', fieldName, 'unitPrice'], null);
        form.setFieldValue(['lines', fieldName, 'discountPer'], 0);
        form.setFieldValue(['lines', fieldName, 'discount'], 0);
        form.setFieldValue(['lines', fieldName, 'vat'], 0);
        form.setFieldValue(['lines', fieldName, 'total'], 0);
        if (removeLine) removeLine(fieldName);
        onUnitPriceFilled({ isFilled: false, index: fieldName });

        throw {
          name: 'ProductError',
          message: 'Product has already been added.'
        };
      }

      form.setFieldValue(['lines', fieldName, 'quantity'], null);
      form.setFieldValue(['lines', fieldName, 'unitPrice'], null);

      // Finds Product Unit and Sets Product Default Unit
      if (productSearch.length > 0) {
        const selectedProduct = productSearch.find((val) => val.id == productId);
        const unitIdList = selectedProduct?.productUnits;
        const defaultUnit = unitIdList?.find((currUnit) => currUnit.isDefault === true);
        form.setFieldValue(['lines', fieldName, 'unitId'], defaultUnit?.unitId);
        let fetch = false;
        if (unitIdList) {
          for (const value of unitIdList) {
            let unitData;
            try {
              unitData = await UnitsDB.getUnit(value.unitId);
            } catch (e) {
              console.log(e);
            }
            if (!unitData) {
              const response = await get_units_list();
              await UnitsDB.addUnits(response);
              fetch = true;
            }
          }
          if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
        }
      }

      // Filter Lots according to Preference Location and Product
      // console.log('locationInfo', locationInfo);
      const lots = await fetchLotsOnProductChange([productId], 'productChange', fieldName);

      if (lots && lots.length !== 0) {
        const filteredLots = lots
          .filter((val) => val.productId == productId && val.qtyAvailable > 0)
          .sort((a, b) => {
            return a.grade.localeCompare(b.grade) || b.qtyAvailable - a.qtyAvailable;
          });
        if (filteredLots.length !== 0) {
          form.setFieldValue(['lines', fieldName, 'lotId'], filteredLots[0].id);
          form.setFieldValue(['lines', fieldName, 'hsCode'], filteredLots[0].hsCode);
        }
      }

      // Find and set Product Price Group
      // await initializePriceGroup();
      const selectedUnitId = form.getFieldValue(['lines', fieldName, 'unitId']);
      const selectedProductId = form.getFieldValue(['lines', fieldName, 'productId']);
      if (!locationInfo) {
        throw {
          name: 'LocationError',
          message: 'Location not found.'
        };
      }

      let isProductUnitPriceFilled = false;
      for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
        let currDefaultPrice = [];
        if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
          currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
        } else {
          const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
          setpricegroupsId((prev: any) => ({
            ...prev,
            [pricegroupDropdown[ind].id]: response2.data
          }));
          currDefaultPrice = response2.data;
        }
        const findOne = currDefaultPrice.find(
          (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
        );
        if (findOne) {
          isProductUnitPriceFilled = findOne.sellingPrice > 0;
          form.setFieldValue(['lines', fieldName, 'unitPrice'], findOne.sellingPrice);
          form.setFieldValue(['lines', fieldName, 'priceGroupId'], findOne.priceGroupId);
          break;
        } else {
          isProductUnitPriceFilled = false;
          form.setFieldValue(['lines', fieldName, 'unitPrice'], 0);
          form.setFieldValue(['lines', fieldName, 'priceGroupId'], null);
        }
      }

      onUnitPriceFilled({ isFilled: isProductUnitPriceFilled, index: fieldName });

      // Find Discount
      const customerId = form.getFieldValue(['userId']);
      const updatedLines = form.getFieldValue(['lines']);
      const linesWithQuantity = updatedLines
        .filter((line: any) => line?.productId)
        .map((line: any) => {
          return {
            ...line,
            quantity: line.quantity || 0,
            discount: parseFloat(line.discount) || 0,
            misc: line.misc || 0,
            vat: parseFloat(line.vat) || 0
          };
        });

      if (locationInfo && customerId) {
        const topOffer = await get_sell_discount(locationInfo.id, customerId, linesWithQuantity);
        if (topOffer) {
          for (let i = 0; i < lines.length; i++) {
            if (lines[i]?.productId) {
              const discPer = topOffer.find(
                (val) => val.productId === lines[i].productId
              )?.discountPercent;
              form.setFieldValue(['lines', i, 'discountPer'], discPer);
              onPriceandQuantityChange(i);
            }
          }
        } else {
          throw {
            name: 'OfferError',
            message: 'Offer not found.'
          };
        }
      } else {
        throw {
          name: 'LocationError',
          message: 'Location not found.'
        };
      }
    } catch (errors: any) {
      message.error(errors.message);
    }
    setFormValues(form.getFieldsValue());
    console.log('onProductChange running');

    setIsloading(false);
  };

  const fetchLotsOnProductChange = async (
    productsIdArray: number[],
    from: string,
    fieldName?: number
  ) => {
    try {
      if (!locationInfo?.id) {
        throw {
          name: 'Location Error',
          message: 'Please add Location in Preferences!'
        };
      }

      const filterNulls = productsIdArray.filter((val) => val);
      if (filterNulls.length === 0) return [];

      if (from === 'productChange') {
        let filterLots = totalLots.find((value) => value.productId == filterNulls[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationInfo.id,
            filterNulls[0]
          );
          settotalLots([...totalLots, ...response]);
          filterLots = response[0];
          return [...totalLots, ...response];
        }
        if (filterLots && fieldName) {
          form.setFieldValue(['lines', fieldName, 'lotId'], filterLots.id);
          form.setFieldValue(['lines', fieldName, 'hsCode'], filterLots.hsCode);
          return [...totalLots];
        }
      } else if (from === 'lotsUpdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationInfo.id, [
          ...new Set(filterNulls)
        ]);

        settotalLots((prev) => {
          const filterLots = prev.filter(
            (value: ILotDetails) => !filterNulls.includes(value.productId)
          );

          return [...filterLots, ...result];
        });
      }
    } catch (errors: any) {
      CustomErrorModal({ message: errors.message });
    }
  };

  const onUnitChange = async (fieldName: number) => {
    const currentLocationId = locationInfo?.id;
    if (!currentLocationId) {
      CustomErrorModal({ message: 'Please add Location in Preferences!' });
      return;
    }
    const selectedUnitId = form.getFieldValue(['lines', fieldName, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', fieldName, 'productId']);

    let isUnitPriceFilled = false;
    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      // console.log('findone', findOne);

      if (findOne) {
        form.setFieldValue(['lines', fieldName, 'unitPrice'], findOne.sellingPrice);
        form.setFieldValue(['lines', fieldName, 'priceGroupId'], findOne.priceGroupId);
        isUnitPriceFilled = findOne.sellingPrice > 0;
        break;
      } else {
        form.setFieldValue(['lines', fieldName, 'unitPrice'], 0);
        form.setFieldValue(['lines', fieldName, 'priceGroupId'], null);
        isUnitPriceFilled = false;
      }
    }
    onPriceandQuantityChange(fieldName);
    onUnitPriceFilled({ isFilled: isUnitPriceFilled, index: fieldName });
  };

  const onPriceandQuantityChange = async (fieldName: number) => {
    const rate = form.getFieldValue(['lines', fieldName, 'unitPrice']);
    const qty = form.getFieldValue(['lines', fieldName, 'quantity']);

    let total = rate * qty;
    const selectedProductId = form.getFieldValue(['lines', fieldName, 'productId']);

    // For Discount
    const discountPer = form.getFieldValue(['lines', fieldName, 'discountPer']);
    const discount = roundNumber((total * discountPer) / 100);
    total = parseFloat((total - discount).toFixed(2));

    form.setFieldValue(['lines', fieldName, 'discount'], discount);

    let vat = 0;
    if (!selectedProductId) {
      message.error('Please select Product.');
      return;
    }
    //For Lines Vat and Total
    let productDetails = await ProductsDB.getProduct(selectedProductId);
    if (!productDetails) {
      const allProduct = await get_product_details(selectedProductId);
      await ProductsDB.addProducts([allProduct]);
      productDetails = await ProductsDB.getProduct(selectedProductId);
    }
    vat =
      typeof productDetails === 'object' && productDetails.vat !== undefined
        ? productDetails.vat
        : 0;
    const vatAmt = total * (vat / 100);
    form.setFieldValue(['lines', fieldName, 'vat'], vatAmt);
    if (typeof total == 'number') {
      const totalWithVat = total + vatAmt;
      form.setFieldValue(['lines', fieldName, 'total'], total);
      form.setFieldValue(['lines', fieldName, 'totalWithVat'], totalWithVat);
    }

    // For Grand Total
    const lines = form.getFieldValue(['lines']);
    let grandTotal = 0;
    let totalAmt = 0;
    let taxable = 0;
    let nonTaxable = 0;
    let vatTotal = 0;
    let discountTotal = 0;

    for (let i = 0; i < lines.length; i++) {
      lines[i].total = parseFloat(lines[i].total);
      lines[i].discount = parseFloat(lines[i].discount);
      totalAmt += lines[i].total;
      grandTotal += lines[i].total;
      discountTotal += lines[i]?.discount || 0;

      if (typeof productDetails === 'object' && productDetails.vat != undefined) {
        const vat = lines[i].vat;
        if (vat > 0) {
          vatTotal += vat;
          taxable += lines[i].total;
        } else {
          nonTaxable += lines[i].total;
        }
      }
    }

    form.setFieldValue(['total'], totalAmt.toFixed(2));
    form.setFieldValue(['taxable'], taxable.toFixed(2));
    form.setFieldValue(['nonTaxable'], nonTaxable.toFixed(2));
    form.setFieldValue(['vat'], vatTotal.toFixed(2));
    form.setFieldValue(['discount'], discountTotal.toFixed(2));
    form.setFieldValue(['grandtotal'], (grandTotal + vatTotal).toFixed(2));
    calculateTenderReturnAmount();

    const shippingCost = find_shipping_cost(grandTotal);
    form.setFieldValue(['shipping'], shippingCost);
  };

  const calcTotal = () => {
    // For Grand Total
    const lines = form.getFieldValue(['lines']);
    let grandTotal = 0;
    let totalAmt = 0;
    let taxable = 0;
    let nonTaxable = 0;
    let vatTotal = 0;
    let discountTotal = 0;

    for (let i = 0; i < lines.length; i++) {
      lines[i].total = parseFloat(lines[i].total);
      lines[i].discountTotal = parseFloat(lines[i].discountTotal);
      totalAmt += lines[i].total;
      grandTotal += lines[i].total;
      discountTotal += parseFloat(lines[i]?.discount || 0);

      const vat = lines[i].vat;
      if (vat > 0) {
        vatTotal += vat;
        taxable += lines[i].total;
      } else {
        nonTaxable += lines[i].total;
      }
    }

    form.setFieldValue(['total'], totalAmt.toFixed(2));
    form.setFieldValue(['taxable'], taxable.toFixed(2));
    form.setFieldValue(['nonTaxable'], nonTaxable.toFixed(2));
    form.setFieldValue(['vat'], vatTotal.toFixed(2));
    form.setFieldValue(['discount'], discountTotal.toFixed(2));
    form.setFieldValue(['grandtotal'], (grandTotal + vatTotal).toFixed(2));
    calculateTenderReturnAmount();

    const shippingCost = find_shipping_cost(grandTotal);
    form.setFieldValue(['shipping'], shippingCost);
  };

  const onPriceGroupChange = async (fieldName: number) => {
    const productInfo = form.getFieldValue(['lines', fieldName]);
    // console.log('product Info', productInfo);
    let defaultPrice = [];
    const locationId = locationInfo?.id;
    // console.log('pricegroupsgroupId', pricegroupsgroupId);
    if (locationId && productInfo.priceGroupId) {
      if (!pricegroupsgroupId[productInfo.priceGroupId]) {
        const response2 = await prices_by_groupId(productInfo.priceGroupId);
        defaultPrice = [...response2.data];
        // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
        setpricegroupsId((prev: any) => ({
          ...prev,
          [productInfo.priceGroupId]: response2.data
        }));
      } else {
        defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
      }
    }

    const alreadySelectedUnitId = form.getFieldValue(['lines', fieldName, 'unitId']);
    const findone = defaultPrice.find(
      (res2elem: any) =>
        res2elem.productId == productInfo.productId && res2elem.unitId == alreadySelectedUnitId
    );
    if (findone) {
      form.setFieldValue(['lines', fieldName, 'unitPrice'], findone.sellingPrice);
    } else {
      form.setFieldValue(['lines', fieldName, 'unitPrice'], 0);
    }

    onUnitPriceFilled({ isFilled: Boolean(findone?.sellingPrice > 0), index: fieldName });
  };

  const optionsUser = userSearch.map((value: any) => (
    <Option key={value.id} value={value.id}>
      <div className="flex justify-between">
        <div>
          {' '}
          {value.name ? value.name : value?.user?.name},{' '}
          {value.phone ? value.phone : value?.user?.phone}{' '}
        </div>
      </div>
    </Option>
  ));

  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );

      const filteredUnits = selectedProduct?.productUnits.map((value) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits?.map((value) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      CustomErrorModal({ message: 'Failed to get product id' });
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const currentLocation = locationInfo?.id;

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: ILotDetails[] = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter((currLot) => currLot.productId === checkCurrentProduct);
        // console.log('filtered Lots-->', filteredLots);
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo = allLocalUnits.find((val) => unitId == val.id);
      // console.log('lotID', form.getFieldValue(['lines', name, 'lotId']));
      if (!form.getFieldValue(['lines', name, 'lotId'])) {
        filteredLots.sort((a, b) => b.qtyAvailable - a.qtyAvailable);

        if (filteredLots.length > 0) {
          form.setFieldValue(['lines', name, 'lotId'], filteredLots[0].id);
          form.setFieldValue(['lines', name, 'hsCode'], filteredLots[0].hsCode);
        }
      }

      return (
        <>
          {filteredLots?.map((value) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)} ${
                unitInfo?.shortName || ''
              }) ${value.lotNumber}`}{' '}
              {`Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };

  const FilterPriceGroup = () => {
    return (
      <>
        {pricegroupDropdown?.map((value) => (
          <Option value={value.id} key={value.id}>
            {value.name}
          </Option>
        ))}
      </>
    );
  };

  const createSellMutation = useMutation(create_sell_mutation);
  const createSellAndPaymentMutation = useMutation(create_sell_payment_mutation);

  const createSellPayment = useMutation(create_sell_payment);

  const createFonePayMutation = useMutation(create_fonepay_mutation);

  async function checkDuplicateSell(options: ICheckDuplicate, values?: any) {
    if (!selectedCustomer) {
      CustomErrorModal({ message: 'Please select customer' });
      return false;
    }

    const sellValues = values
      ? { ...values, customerId: selectedCustomer.id, discount: 0 }
      : { ...Values, customerId: selectedCustomer.id, discount: 0 };

    setIsloading(true);
    const response = await check_sell_duplicate(sellValues);
    if (response === false) return false;
    setAlreadyCreatedSell(response.sell);
    if (response.payments.length === 0) {
      if (options.payType === 'fonepay') {
        await addPayment(response.sell, 'fonepay', options.prn);
      }

      if (options.payType === 'cash' || options.payType === 'card') {
        await addPayment(response.sell, options.payType);
      }

      await generateBillForPrint(response.sell);
      resetPOSData();
    } else {
      // Show Wanna Print Invoice
      setOpenAskingForPrintModal(true);
      setShowConfirmPaymentModal(false);
      setIsQRModalOpen(false);
    }
    return true;
  }

  const onFinish = useSingleClick(async (values: any, skipDuplicateCheck = false) => {
    setIsloading(true);
    // Validate
    const lines = form.getFieldValue(['lines']);
    if (!lines[lines.length - 1].productId) {
      values.lines.pop();
    }
    // console.log('values', values);
    setValues({});
    setCopyValues({});
    values.locationId = locationInfo?.id;
    values.paymentType = payType === 'credit' ? SellPaymentType.CREDIT : SellPaymentType.FULL;
    const copyValues = { ...values };
    const total = values.grandtotal;

    delete values.userId;
    delete values.grandtotal;
    delete values.taxable;
    delete values.nonTaxable;
    delete values.total;
    delete values.vat;
    delete values.currentActiveKey;
    delete values.saved;
    values.cratesSent = 0;
    values.adjustment = 0;

    try {
      if (values.lines === undefined) {
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };
      }
      if (values.lines.length === 0) {
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };
      }

      for (let i = 0; i < values.lines.length; i++) {
        delete values.lines[i].total;
        const filteredLot = totalLots.find((value) => value.id == values.lines[i].lotId);
        const unitId = values.lines[i].unitId;
        const findOne = allLocalUnits.find((curr) => curr.id === unitId);

        // Set Product Name
        let productDetails = await ProductsDB.getProduct(values.lines[i].productId);
        if (!productDetails) {
          const allIds: Set<number> = new Set(
            values.lines.map((value: any) => {
              return value.productId;
            })
          );
          const allProducts = await get_product_list_ids([...allIds]);
          await ProductsDB.addProducts(allProducts.data.results);
          productDetails = await ProductsDB.getProduct(values.lines[i].productId);
        }
        copyValues.lines[i].productName =
          typeof productDetails === 'object' ? productDetails.name : '';

        // Validate if quantity exceeds remaining quantity
        if (filteredLot && findOne) {
          const currentQuantity = values.lines[i].quantity * findOne.baseUnitMultiplier;
          if (filteredLot.qtyAvailable < currentQuantity || filteredLot.qtyAvailable === 0) {
            form.setFields([
              {
                name: ['lines', i, 'quantity'],
                errors: [
                  `available quantity: ${filteredLot.qtyAvailable / findOne.baseUnitMultiplier} ${
                    findOne.shortName
                  }`
                ]
              }
            ]);
            throw {
              name: 'QtyError',
              message: `The selected product ${values.lines[i].productName}'s lot has quantity: ${
                filteredLot.qtyAvailable / findOne.baseUnitMultiplier
              }. Cannot send ${values.lines[i].quantity}.`
            };
          }
        } else {
          throw {
            name: 'QtyError',
            message: `Can't find lot/unit for the selected product ${values.lines[i].productName}.`
          };
        }
        values.lines[i].misc = 0;

        // Round New Discount
        values.lines[i].discount = roundNumber(values.lines[i].discount);
      }

      values.discount = values.discount ? parseFloat(values.discount) : 0;
      values.date = JSON.stringify(new Date()).slice(1, -1);
      values.createdBy = loggedInUser.id;
      values.type = 'POS';
      values.note = 'This is created from POS.';
      values.shipping = 0;
      values.isCustomShipping = true;
      values.misc = 0;

      const isLotIdAbsent = values.lines.some((value: any) => !value?.lotId);
      if (isLotIdAbsent) {
        throw {
          name: 'LotError',
          message: 'Please select Lot for all the selected products.'
        };
      }

      setValues({ ...values, totalAmount: parseFloat(total) });

      setCopyValues(copyValues);

      const { lines, locationId } = values;
      const response = await get_sell_discount(locationId, Number(selectValue), lines);

      const changedLines = [];

      for (const data of response) {
        const lineIndex = lines.findIndex((line: any) => line.productId === data.productId);

        if (lineIndex !== -1) {
          const line = lines[lineIndex];
          const isDiscountSame = line.discount === data.discount;
          if (!isDiscountSame) {
            // Replace the discount with the new one in the form values
            form.setFieldValue(['lines', lineIndex, 'discountPer'], data.discountPercent);
            onPriceandQuantityChange(lineIndex);
            changedLines.push(line.productName);
          }
        }
      }

      // if (changedLines.length > 0) {
      //   CustomErrorModal({
      //     message: `Discount for ${changedLines.join(', ')} was updated. Please click pay again!`
      //   });
      //   setIsloading(false);
      //   return;
      // }

      if (payType === 'cash' || payType === 'card' || payType === 'credit') {
        try {
          values.discount = 0;
          if (!skipDuplicateCheck) {
            const isDuplicate = await checkDuplicateSell({ payType }, values);
            if (isDuplicate) return;
          }

          if (payType !== 'credit') {
            const paymentValues = {
              adjustment: false,
              amount: parseFloat(total),
              date: values.date,
              madeBy: customerDetails.userId,
              note: 'This payment is made from pos sell',
              paymentMethod: payType,
              walletPayment: 0,
              reference: 'temp-will-be-overridden-by-server'
            } as IAddPayment;

            const payload = { sell: values, payment: paymentValues };
            await createSellAndPaymentMutation.mutateAsync(payload, {
              onSuccess: async (data) => {
                setIsQRModalOpen(false);

                copyValues.grandtotal = data.sell.totalAmount;
                message.success('Sell and Payment added successfully');
                await generateBillForPrint(data.sell);

                form.resetFields();
                setFormValues({ saved: true });
                form.resetFields(['lines']);
                setPayType('');
              },
              onError: async (error: any) => handleSellCreateError(error)
            });
          }

          if (payType === 'credit') {
            await createSellMutation.mutateAsync(values, {
              onSuccess: async ({ data }) => {
                if (data) {
                  copyValues.grandtotal = data.totalAmount;
                  message.success('Sell added successfully');
                  await generateBillForPrint(data);
                  form.resetFields();
                  setFormValues({ saved: true });
                  form.resetFields(['lines']);
                  setPayType('');
                }
              },
              onError: async (error: any) => handleSellCreateError(error)
            });
          }
        } catch (e) {
          console.log('error');
        }
      } else if (payType === 'epay') {
        let amount = copyValues.grandtotal;
        if (typeof copyValues.grandtotal === 'string') amount = parseFloat(copyValues.grandtotal);
        handleQrModalOpen(amount, copyValues.locationId);
      }
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) {
        CustomErrorModal({ message: errors.message, title: errors.name || 'Error!' });
      } else {
        message.error('Empty fields found!');
      }
    }
  });

  async function handleSellCreateError(error: any) {
    setIsloading(false);
    const message = getErrorMessage(error);
    const isAxios = isAxiosError(error);

    if (message === 'Error Discount is not valid') {
      await getAllDiscounts();
      isAxios
        ? ErrorModalWithLog({
            message: 'Discount was updated. Please click pay again!',
            axiosError: error
          })
        : CustomErrorModal({ message: 'Discount was updated. Please click pay again!' });
      return false;
    }

    if (message.startsWith('Not enough quantity available')) {
      const productMatch = message.match(/product (\d+)/);
      const locationMatch = message.match(/location (\d+)/);

      const productId = productMatch ? productMatch[1] : null;
      const locationId = locationMatch ? locationMatch[1] : null;

      if (productId && locationId) {
        const product = await ProductsDB.getProductDetails(Number(productId));
        const location = await LocationsDB.getLocation(Number(locationId));

        const message = `The available quantity of ${product.name} in ${location.name} is insufficient.`;
        isAxios ? ErrorModalWithLog({ message, axiosError: error }) : CustomErrorModal({ message });
      } else {
        isAxios ? ErrorModalWithLog({ message, axiosError: error }) : CustomErrorModal({ message });
      }
    } else {
      isAxios ? ErrorModalWithLog({ message, axiosError: error }) : CustomErrorModal({ message });
    }
  }

  const handleQrModalOpen = async (amt: number, location: number) => {
    setIsloading(true);

    try {
      // const checkDiscount = await check_discount();
      const checkDiscount = { status: true, offers: [] };
      if (checkDiscount?.status) {
        if (!locationInfo) {
          throw { name: 'LocationError', message: 'Please select location.' };
        }

        const lines = form.getFieldValue(['lines']);
        const actualLines: any[] =
          lines && Array.isArray(lines) ? JSON.parse(JSON.stringify(lines)) : [];
        if (!actualLines[actualLines.length - 1].productId) {
          actualLines.pop();
        }

        if (actualLines.length === 0) {
          throw { name: 'Error', message: 'Please add at least one item.' };
        }

        const remark1 = await getFonePayRemark(
          locationInfo.id,
          customerDetails.id,
          actualLines.length
        );

        const data = { amount: amt, reference: 'test', remark1, locationId: location };
        const response = await get_qr_for_pos(data);
        if (response) {
          if (!response.qr || !response.prn) {
            throw {
              name: 'Error',
              message: 'Failed to generate QR code. Please try again.'
            };
          }
          setQrValue({ qr: response.qr, prn: response.prn });
          initializeFonepaySocket(response.merchantWebsocketUrl);
        }
        setIsQRModalOpen(true);
        setIsloading(false);
      } else {
        setIsloading(false);
        await getAllDiscounts(checkDiscount?.offers);
        throw {
          name: 'Discount Error',
          message: 'Discount was updated. Please click submit again!'
        };
      }
    } catch (errors: any) {
      setIsloading(false);
      if (isAxiosError(errors)) {
        ErrorModalWithLog({ message: getErrorMessage(errors), axiosError: errors });
      } else {
        CustomErrorModal({ message: errors.message });
      }
    }
  };

  const handleCheckPaymentClick = async () => {
    setIsloading(true);
    try {
      const response = await get_payment_status_by_prn(qrValue.prn);
      if (response) {
        if (response.status === 'success') {
          message.success('Payment Successful!');
          const isDuplicate = await checkDuplicateSell({
            payType: 'fonepay',
            prn: qrValue.prn
          });

          if (!isDuplicate) {
            await afterQRSuccess(response.prn);
          }
        } else {
          message.warning('Payment Pending!');
          setIsloading(false);
        }
      } else {
        setIsloading(false);
      }
    } catch (errors: any) {
      setIsloading(false);
      CustomErrorModal({ message: errors.message });
    }
  };

  const handleConfirmPaymentEvenWhenPending = async (skipDuplicateCheck = false) => {
    setShowConfirmPaymentModal(false);
    try {
      if (hasApiBeenCalled.current) return;
      setIsloading(true);

      if (!skipDuplicateCheck) {
        const isDuplicate = await checkDuplicateSell({ payType: 'fonepay', prn: qrValue.prn });
        if (isDuplicate) return;
      }

      fonepaySocket?.close();
      isForcedPayment.current = true;
      hasApiBeenCalled.current = true;

      await afterQRSuccess(qrValue.prn);
      isForcedPayment.current = false;
      hasApiBeenCalled.current = false;
    } catch (errors: any) {
      setIsloading(false);
      CustomErrorModal({ message: errors.message });
    }
  };

  const afterQRSuccessBase = async (prn: string) => {
    try {
      Values.discount = 0;
      setIsloading(true);

      const { totalAmount, ...sellPayload } = Values;

      const paymentValues = {
        adjustment: false,
        amount: totalAmount,
        date: Values.date,
        madeBy: customerDetails.userId,
        note: 'This payment is made from pos sell',
        paymentMethod: 'fonepay',
        walletPayment: 0,
        reference: 'temp-will-be-overridden-by-server'
      } as IAddPayment;

      const payload = { sell: sellPayload, payment: paymentValues };
      await createSellAndPaymentMutation.mutateAsync(payload, {
        onSuccess: async (data) => {
          setIsQRModalOpen(false);
          setIsloading(true);

          if (prn) await addToFonePay(prn, data.sell.id);
          await generateBillForPrint(data.sell);
          message.success('Sell and Payment added successfully');
          form.resetFields();
          form.resetFields(['lines']);
          setPayType('');
        }
      });
    } catch (errors: any) {
      setIsloading(false);
      const errorMessage = getErrorMessage(errors);
      if (isAxiosError(errors)) {
        ErrorModalWithLog({ message: errorMessage, axiosError: errors });
      } else {
        CustomErrorModal({ message: errorMessage });
      }
    }
  };
  const afterQRSuccess = useSingleClick(afterQRSuccessBase);

  function resetPOSData() {
    form.resetFields();
    form.resetFields(['lines']);
    setPayType('');
    setFormValues({});
    setIsloading(false);
  }

  const addToFonePay = async (prn: string, paymentId: number) => {
    try {
      const values = {
        prn: prn,
        paymentId: paymentId
      };

      await createFonePayMutation.mutateAsync(values, {
        onSuccess: async () => {
          message.success('FonePay added successfully');
          fonepaySocket?.close();
        },
        onError: (data: any) => {
          setIsloading(false);
        }
      });
    } catch (errors: any) {
      setIsloading(false);
      if (isAxiosError(errors)) return;
      CustomErrorModal({ message: getErrorMessage(errors) });
    }
  };

  const addPayment = async (values: ISellFromServer, type?: string, prn?: string) => {
    try {
      setIsloading(true);
      const newValues = {
        id: values.id,
        adjustment: false,
        amount: values.totalAmount,
        date: convertLocalToUTCString(moment(), DEFAULT_DATE_FORMAT),
        madeBy: customerDetails.userId,
        note: 'This payment is made from pos sell',
        paymentMethod: type ? type : 'cash',
        reference: values.referenceNumber,
        walletPayment: 0
      };

      createSellPayment.mutate(newValues as IAddPayment, {
        onSuccess: async ({ data }) => {
          message.success('Payment Done successfully!');
          setIsQRModalOpen(false);
          setIsloading(true);

          //console.log('Payment Data', data);
          if (type === 'fonepay' && prn !== undefined) {
            await addToFonePay(prn, data.id);
          }
        },
        onError: () => {
          setIsloading(false);
        }
      });
    } catch (errors: any) {
      setIsloading(false);
      if (isAxiosError(errors)) return;
    }
  };

  async function generateBillIfSellHasBeenCreaed() {
    if (!alreadyCreatedSell) return;
    setOpenAskingForPrintModal(false);
    await generateBillForPrint(alreadyCreatedSell);
    resetPOSData();
  }

  async function handleAskingForPrintModalClose() {
    setOpenAskingForPrintModal(false);
    // resetPOSData();
  }

  const generateBillForPrint = async (data: ISellFromServer) => {
    setIsloading(true);
    try {
      const billModalData = await getSellPrintData(data.id, data.locationId, data.sellOrderId);
      // console.log('billModalData', billModalData);

      let invoiceLayouts;
      if (defaultBillPrint) {
        invoiceLayouts = (await get_invoices_details(defaultBillPrint.toString())).data;
      } else if (invoiceLists) {
        invoiceLayouts = invoiceLists.find((curr) => {
          const content = JSON.parse(curr.content);
          return content.design == 'bill';
        });
      } else {
        CustomErrorModal({ message: 'Could not generate bill. No bill layout found!' });
      }

      const paymentType = payType;

      if (invoiceLayouts) {
        setBillData({ ...billModalData, invoiceLayouts: invoiceLayouts, paymentType: paymentType });
        setOpenModalForBillPrint(true);
      }

      setIsloading(false);
    } catch (errors: any) {
      setIsloading(false);
      CustomErrorModal({ message: errors.message });
    }
  };

  const handleBillPrintModalClose = () => {
    setOpenModalForBillPrint(false);
  };

  const handleAddCustomer = () => {
    setIsAddCostumerModalOpen(true);
  };

  const handleAddCustomerModalClose = async (customerId: number, searchValue: string) => {
    setIsloading(true);
    //console.log('customerId', customerId);
    setIsAddCostumerModalOpen(false);
    await searchUser(searchValue);
    setselectValue(customerId);
    form.setFieldValue('userId', customerId);
    await onUserChange(customerId);
    setIsloading(false);
  };

  const submitForm = async () => {
    try {
      if (address.length === 0) {
        CustomErrorModal({
          title: 'AddressError',
          message: 'Please add address for the selected customer.'
        });
        return false;
      }
      await form.submit();
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
      return false;
    }
  };

  const onClearForm = () => {
    form.resetFields();
    setFormValues({});
    form.resetFields(['lines']);
  };

  const onLineRemoved = async (index: number) => {
    const currentFormValues = form.getFieldsValue();
    // this is done to handle empty objects which are added to array by antd when it internally removes items
    const lines = JSON.parse(JSON.stringify(currentFormValues.lines)).filter(
      (value: any) => Object.keys(value).length !== 0
    );
    // console.log('lines', lines);
    setFormValues({ ...currentFormValues, lines });
  };

  const calculateTenderReturnAmount = () => {
    let grandTotal = form.getFieldValue(['grandtotal']);
    let tenderAmount = form.getFieldValue(['tenderAmount']);
    if (grandTotal === null || grandTotal === '') grandTotal = 0;
    if (tenderAmount === null || tenderAmount === '') tenderAmount = 0;
    const returnAmount = tenderAmount - grandTotal;
    form.setFieldValue(['tenderReturnAmount'], parseFloat(returnAmount.toFixed(2)));
  };

  async function handleReCreateSell() {
    setOpenAskingForPrintModal(false);
    if (payType === 'epay') {
      await handleConfirmPaymentEvenWhenPending(true);
      return;
    }

    const formValues = form.getFieldsValue();
    await onFinish(formValues, true);
  }

  return (
    <PosSessionProvider isSessionStart={isSessionStart} setIsSessionStart={setIsSessionStart}>
      <PosLayout form={form} formValues={formValues}>
        <Spin spinning={isLoading}>
          <CustomModal
            footer={false}
            isModalOpen={openModalForBillPrint}
            setIsModalOpen={setOpenModalForBillPrint}
            title="Bill Print">
            <BillPrint
              sellDetails={billData.sellDetails}
              customerDetails={billData.customerDetails}
              lines={billData.lines}
              firstCreatedByUserDetails={billData.firstCreatedByUserDetails}
              invoiceLayouts={billData.invoiceLayouts}
              paymentType={billData.paymentType}
              handleModalClose={handleBillPrintModalClose}
            />
          </CustomModal>

          <CustomModal
            width="600px"
            footer={false}
            isModalOpen={openAskingForPrintModal}
            setIsModalOpen={(val) => {
              setOpenAskingForPrintModal(val);
              if (!val) setIsloading(false);
            }}
            title="Sell Confirmation?">
            <div className="flex flex-col justify-center items-center gap-5">
              <div>
                A similar sale has already been created. Would you like to create a new sale? To
                view the existing sale, click{' '}
                <Link to={`/sell/${alreadyCreatedSell?.id}`} target="_blank">
                  here
                </Link>
                .
              </div>

              <div className="flex justify-end items-center w-full flex-wrap gap-2.5">
                <CustomButton
                  text="Create New Sell"
                  backgroundColor="#ff4d4f"
                  textColor="white"
                  marginBottom="0"
                  marginLeft="0"
                  onClick={handleReCreateSell}
                />
                <CustomButton
                  backgroundColor="#cccccc"
                  textColor="black"
                  text="Generate Bill For Existing Sell"
                  marginBottom="0"
                  marginLeft="0"
                  onClick={generateBillIfSellHasBeenCreaed}
                />
              </div>
            </div>
          </CustomModal>

          <CustomModal
            isModalOpen={isAddCostumerModalOpen}
            setIsModalOpen={setIsAddCostumerModalOpen}
            title={'Add Customer'}
            footer={false}>
            <ReusableCustomerCreate handleModalClose={handleAddCustomerModalClose} />
          </CustomModal>

          <CustomModal
            isModalOpen={showConfirmPaymentModal}
            setIsModalOpen={setShowConfirmPaymentModal}
            title={'Confirm Payment'}
            width="350px"
            footer={false}>
            <div className="flex flex-col justify-center items-center gap-5">
              Are you sure you want to confirm the payment?
              <div className="flex justify-center items-center">
                <CustomButton
                  text="Confirm"
                  backgroundColor="#3333cc"
                  textColor="white"
                  onClick={() => handleConfirmPaymentEvenWhenPending(false)}
                />
                <CustomButton
                  text="Cancel"
                  backgroundColor="#cccccc"
                  textColor="black"
                  onClick={() => setShowConfirmPaymentModal(false)}
                />
              </div>
            </div>
          </CustomModal>

          <NotifySellPriceModal
            title={'Confirmation'}
            visible={confirm}
            onOk={async () => {
              setConfirm(false);
              if (payType === 'cash') {
                const grandTotal = form.getFieldValue(['grandtotal']);
                if (grandTotal) {
                  setIsAddTenderModalOpen(true);
                } else {
                  message.warning('Please add amount first');
                  setPayType('');
                  form.setFieldValue(['tenderAmount'], null);
                  form.setFieldValue(['tenderReturnAmount'], null);
                }
              } else await submitForm();
            }}
            onCancel={() => {
              setConfirm(false);
            }}
            data={notifyModalData}
          />
          <CustomModal
            isModalOpen={isAddTenderModalOpen}
            setIsModalOpen={setIsAddTenderModalOpen}
            title={'Add Tender Amount'}
            width={'500px'}
            footer={false}>
            <Spin spinning={isLoading}>
              <Form.Item label="Tender Amount" initialValue={0}>
                <InputNumber
                  controls={false}
                  min={0}
                  precision={0}
                  value={tenderAmount}
                  onChange={async (val) => {
                    const grandTotal = form.getFieldValue(['grandtotal']);
                    if (val !== null) {
                      setTenderAmount(parseFloat(val.toFixed(2)));
                      const tenderReturnAmount = val - grandTotal;
                      form.setFieldValue(['tenderAmount'], parseFloat(val.toFixed(2)));
                      form.setFieldValue(
                        ['tenderReturnAmount'],
                        parseFloat(tenderReturnAmount.toFixed(2))
                      );
                      setTenderReturnAmount(parseFloat(tenderReturnAmount.toFixed(2)));
                    } else {
                      message.warning('Please add tender amount');
                      form.setFieldValue(['tenderAmount'], null);
                      form.setFieldValue(['tenderReturnAmount'], null);
                      setTenderAmount(0);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Return Amount">
                <InputNumber
                  controls={false}
                  min={0}
                  disabled
                  value={tenderReturnAmount}
                  style={{ color: 'black' }}
                />
              </Form.Item>
              <div className="flex justify-end mt-5">
                <Button
                  disabled={isLoading}
                  type="primary"
                  onClick={async () => {
                    setIsloading(true);
                    setTenderAmount(0);
                    setTenderReturnAmount(0);
                    setIsAddTenderModalOpen(false);
                    await submitForm();
                  }}>
                  Confirm
                </Button>
              </div>
            </Spin>
          </CustomModal>

          <CustomModal
            isModalOpen={isQRModalOpen}
            setIsModalOpen={setIsQRModalOpen}
            width="350px"
            title={'Scan To Pay'}
            footer={false}>
            <Spin spinning={isLoading}>
              <div className="flex flex-col justify-center items-center gap-5">
                <QRCodeSVG
                  value={qrValue.qr}
                  size={300}
                  bgColor={'#ffffff'}
                  fgColor={'#000000'}
                  level={'L'}
                  includeMargin={false}
                  imageSettings={{
                    src: logo,
                    x: undefined,
                    y: undefined,
                    height: 30,
                    width: 30,
                    excavate: true
                  }}
                />
                <div className="flex justify-center items-center gap-10">
                  <CustomButton
                    text="Check Payment"
                    backgroundColor="#3333cc"
                    textColor="white"
                    marginLeft="0px"
                    disabled={hasApiBeenCalled.current}
                    onClick={async () => {
                      if (hasApiBeenCalled.current) return;
                      hasApiBeenCalled.current = true;
                      await handleCheckPaymentClick();
                      hasApiBeenCalled.current = false;
                    }}
                  />
                  <Dropdown overlay={<Menu items={items} onClick={onClick} />} trigger={['click']}>
                    <Button
                      style={{ backgroundColor: '#FF4D4F', color: 'white', borderRadius: '6px' }}
                      className="ml-2">
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Spin>
          </CustomModal>
          <Form
            form={form}
            onFinish={(values: any) => onFinish(values)}
            layout="vertical"
            validateTrigger={'onChange'}
            disabled={!isSessionStart}
            onValuesChange={(changedValues, allValues) => {
              setFormValues(allValues);
              setProductDetails(allValues);
            }}
            autoComplete="off"
            className="p-2">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-4 gap-5 mb-3">
              <div className="cols-span-1 md:col-span-1 lg:col-span-1 xl:col-span-3 gap-5 flex flex-col">
                <Card
                  className="flex-shrink"
                  style={{
                    borderRadius: '10px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                  bodyStyle={{ padding: '10px 10px 5px 10px' }}>
                  <div className="grid grid-cols-2 gap-5">
                    <Form.Item name="currentActiveKey" hidden></Form.Item>
                    <Form.Item name="saved" hidden></Form.Item>
                    <Form.Item
                      name={['userId']}
                      rules={[
                        {
                          required: true,
                          message: 'Please choose Customer!'
                        }
                      ]}>
                      <Select
                        showSearch
                        placeholder={'search customer'}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onChange={(value) => {
                          setselectValue(value);
                          onUserChange(value);
                        }}
                        dropdownMatchSelectWidth={false}
                        onSearch={(val) => {
                          setSearchValueUser(val);
                        }}
                        notFoundContent={null}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />

                            <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                              {isMore ? (
                                <Button
                                  type="text"
                                  style={{
                                    color: 'blue',
                                    width: '100%'
                                  }}
                                  onClick={() => {
                                    searchMoreUser(searchValueUser);
                                  }}>
                                  {/* {'Sync & Search on Server'} */}
                                  {'Get More...'}
                                </Button>
                              ) : (
                                <div
                                  style={{
                                    width: '100%',
                                    textAlign: 'center'
                                  }}>
                                  No more data...
                                </div>
                              )}
                            </div>
                          </>
                        )}>
                        {optionsUser}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['addressId']}
                      rules={[
                        {
                          required: true,
                          message: 'Please choose an address!'
                        }
                      ]}
                      hidden>
                      <Select placeholder="Select a address!" dropdownMatchSelectWidth={false}>
                        {address?.map((value: IUSerlocation) => {
                          return (
                            <Option value={value.id} key={value.id}>
                              {`${value.addressLine1}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Button
                      style={{ backgroundColor: '#3333cc', color: 'white', borderRadius: '6px' }}
                      onClick={handleAddCustomer}
                      className="w-40">
                      Add Customer
                    </Button>
                  </div>
                </Card>

                <Card
                  className="flex-1"
                  style={{
                    borderRadius: '10px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    maxHeight: '100vh',
                    overflow: 'auto'
                  }}
                  bodyStyle={{ padding: '0px' }}>
                  <table
                    className="table-auto w-full overflow-auto tableForPos"
                    cellPadding={'5px 0px'}>
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>PRODUCT NAME</th>
                        <th>UNIT</th>
                        <th>QUANTITY</th>
                        <th>RATE</th>
                        <th>DISCOUNT</th>
                        <th>TAX</th>
                        <th>TOTAL</th>
                        <th>LOT</th>
                        <th>HS CODE</th>
                        <th>PRICE GROUP</th>
                        <th
                          style={{
                            position: 'sticky',
                            right: 0,
                            backgroundColor: 'white',
                            borderLeft: '1px solid gainsboro'
                          }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <Form.List
                      name={['lines']}
                      initialValue={[
                        {
                          productId: null,
                          unitId: null,
                          lotId: null,
                          quantity: null,
                          priceGroupId: null,
                          unitPrice: null,
                          vat: 0,
                          total: 0
                        }
                      ]}>
                      {(fields, { add, remove }) => {
                        removeLine = remove;
                        return (
                          <>
                            <tbody>
                              {fields.map((field, index) => (
                                <tr key={field.key}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <ProductSearchForPos
                                      formData={{ formName: [field.name, 'productId'] }}
                                      onProductChange={async (productId) => {
                                        const lines = form.getFieldValue(['lines']);
                                        //console.log('lines', lines[lines.length - 1].productId);
                                        if (lines[lines.length - 1].productId) {
                                          if (lines[lines.length - 1].productId !== null) {
                                            add();
                                          }
                                        }
                                        await onProductChange(productId, field.name);
                                        setProductList((prev) => [...prev, ...productSearch]);
                                        QuantityRef.current[field.name]?.focus();
                                      }}
                                      productSearch={productSearch}
                                      setProductSearch={setProductSearch}
                                      autofocusRef={autofocusRef}
                                      validation={
                                        form.getFieldValue(['lines']).length - 1 === field.name &&
                                        field.name > 0
                                      }
                                      locationId={locationInfo?.id}
                                      disabled={!isSessionStart}
                                    />
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, 'unitId']}
                                      rules={[
                                        () => ({
                                          validator(rule, value) {
                                            if (
                                              form.getFieldValue(['lines']).length - 1 ===
                                                field.name &&
                                              field.name > 0
                                            ) {
                                              return Promise.resolve();
                                            } else {
                                              if (value) {
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject('Required!');
                                              }
                                            }
                                          }
                                        })
                                      ]}>
                                      {
                                        <Select
                                          placeholder="Select a Unit!"
                                          onChange={() => onUnitChange(field.name)}
                                          dropdownMatchSelectWidth={false}
                                          style={{ width: '100px' }}
                                          allowClear>
                                          {FilterUnits(field.name)}
                                        </Select>
                                      }
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, 'quantity']}
                                      rules={[
                                        () => ({
                                          validator(rule, value) {
                                            if (
                                              form.getFieldValue(['lines']).length - 1 ===
                                                field.name &&
                                              field.name > 0
                                            ) {
                                              return Promise.resolve();
                                            } else {
                                              if (value) {
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject('Required!');
                                              }
                                            }
                                          }
                                        })
                                      ]}>
                                      {
                                        <InputNumber
                                          controls={false}
                                          ref={(el) => (QuantityRef.current[field.name] = el)}
                                          min={0}
                                          precision={3}
                                          onChange={() => {
                                            onPriceandQuantityChange(field.name);
                                          }}
                                          style={{ width: '100px' }}
                                          autoFocus={true}
                                        />
                                      }
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, 'unitPrice']}
                                      rules={[
                                        () => ({
                                          validator(_, value) {
                                            if (
                                              form.getFieldValue(['lines']).length - 1 ===
                                                field.name &&
                                              field.name > 0
                                            ) {
                                              return Promise.resolve();
                                            } else {
                                              if (value) {
                                                if (value == 0) return Promise.reject('Invalid!');
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject('Required!');
                                              }
                                            }
                                          }
                                        })
                                      ]}>
                                      <InputNumber
                                        controls={false}
                                        min={0}
                                        precision={3}
                                        disabled={
                                          addRestricion ? isUnitPriceFilled[field.name] : false
                                        }
                                        onChange={() => onPriceandQuantityChange(field.name)}
                                        style={{ minWidth: '60px' }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, 'discountPer']}
                                      initialValue="0"
                                      hidden>
                                      <InputNumber controls={false} disabled={true} />
                                    </Form.Item>
                                    <Form.Item name={[field.name, 'discount']} initialValue="0">
                                      <InputNumber
                                        controls={false}
                                        bordered={false}
                                        precision={2}
                                        disabled={true}
                                        style={{
                                          minWidth: '50px',
                                          color: 'black',
                                          cursor: 'default'
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name={[field.name, 'vat']} initialValue="0">
                                      <InputNumber
                                        controls={false}
                                        bordered={false}
                                        precision={2}
                                        disabled={true}
                                        style={{
                                          minWidth: '60px',
                                          color: 'black',
                                          cursor: 'default'
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td hidden>
                                    <Form.Item name={[field.name, 'total']} initialValue="0" hidden>
                                      <InputNumber
                                        controls={false}
                                        bordered={false}
                                        precision={2}
                                        disabled={true}
                                        style={{
                                          minWidth: '70px',
                                          color: 'black',
                                          cursor: 'default'
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name={[field.name, 'totalWithVat']} initialValue="0">
                                      <InputNumber
                                        controls={false}
                                        bordered={false}
                                        precision={2}
                                        disabled={true}
                                        style={{
                                          minWidth: '70px',
                                          color: 'black',
                                          cursor: 'default'
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, 'lotId']}
                                      rules={[
                                        () => ({
                                          validator(rule, value) {
                                            if (
                                              form.getFieldValue(['lines']).length - 1 ===
                                                field.name &&
                                              field.name > 0
                                            ) {
                                              return Promise.resolve();
                                            } else {
                                              if (value) {
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject('Required!');
                                              }
                                            }
                                          }
                                        })
                                      ]}>
                                      {
                                        <Select
                                          placeholder="Select a Lot!"
                                          dropdownMatchSelectWidth={false}
                                          onChange={(value) => {
                                            form.setFieldValue(
                                              ['lines', field.name, 'lotId'],
                                              value
                                            );

                                            onLotChange(field.name, value);
                                          }}
                                          style={{ width: '120px' }}
                                          allowClear>
                                          {FilterLot(field.name)}
                                        </Select>
                                      }
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name={[field.name, 'hsCode']}>
                                      <Input disabled className="!w-28" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name={[field.name, 'priceGroupId']}>
                                      {
                                        <Select
                                          placeholder="Select price group"
                                          dropdownMatchSelectWidth={false}
                                          disabled={true}
                                          style={{ width: '110px' }}
                                          onChange={() => onPriceGroupChange(field.name)}
                                          allowClear>
                                          {FilterPriceGroup()}
                                        </Select>
                                      }
                                    </Form.Item>
                                  </td>
                                  <td
                                    style={{
                                      position: 'sticky',
                                      right: 0,
                                      backgroundColor: 'white',
                                      borderLeft: '1px solid gainsboro',
                                      textAlign: 'center'
                                    }}>
                                    <Button
                                      size="small"
                                      type="text"
                                      style={{
                                        borderRadius: '6px',
                                        backgroundColor: '#3333cc',
                                        color: 'white'
                                      }}
                                      onClick={() => {
                                        remove(field.name);
                                        removeFromUnitPrice(field.name);
                                        calcTotal();
                                        onLineRemoved(field.name);
                                      }}>
                                      <DeleteFilled />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={11}>
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                      }}
                                      block
                                      style={{ width: '100%' }}>
                                      <PlusOutlined /> Add field
                                    </Button>
                                  </Form.Item>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      }}
                    </Form.List>
                  </table>
                </Card>
              </div>
              <div className="h-full">
                <Card
                  className="h-full"
                  style={{
                    borderRadius: '10px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}>
                  <div className="grid grid-cols-2">
                    <div className="font-bold">Bill ID</div>
                    <div className="font-bold">: 123</div>
                    <div className="font-bold">Bill Date</div>
                    <div className="font-bold">: {moment().format('MM/DD/YYYY')}</div>
                    <div className="font-bold">Cashier</div>
                    <div className="font-bold">: {loggedInUser.name}</div>
                  </div>
                  <div className="text-lg font-bold text-center mt-2">SUMMARY</div>
                  <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-1 gap-x-5">
                    <Form.Item name={['total']} label="Total Amount">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                    <Form.Item name={['taxable']} label="Taxable">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                    <Form.Item name={['nonTaxable']} label="Non Taxable">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                    <Form.Item
                      label="Discount"
                      name={['discount']}
                      rules={[{ required: true, message: 'Please add Discount!' }]}
                      initialValue={'0'}>
                      <InputNumber
                        controls={false}
                        min={0}
                        disabled={true}
                        style={{ color: 'black' }}
                        onChange={(val) => {
                          const total =
                            parseFloat(form.getFieldValue(['total'])) +
                            parseFloat(form.getFieldValue(['vat']));
                          const tenderAmount = form.getFieldValue(['tenderAmount']);
                          if (val !== null) {
                            const grandTotal = total - val;
                            if (grandTotal > 0) {
                              form.setFieldValue(['grandtotal'], grandTotal.toFixed(2));
                              const tenderReturnAmount = tenderAmount - grandTotal;
                              form.setFieldValue(
                                ['tenderReturnAmount'],
                                parseFloat(tenderReturnAmount.toFixed(2))
                              );
                            } else {
                              form.setFieldValue(['discount'], 0);
                              form.setFieldValue(['grandtotal'], total.toFixed(2));
                              const tenderReturnAmount = tenderAmount - total;
                              form.setFieldValue(
                                ['tenderReturnAmount'],
                                parseFloat(tenderReturnAmount.toFixed(2))
                              );
                              message.warning('Invalid discount amount!');
                            }
                          } else {
                            form.setFieldValue(['grandtotal'], total.toFixed(2));
                            const tenderReturnAmount = tenderAmount - total;
                            form.setFieldValue(
                              ['tenderReturnAmount'],
                              parseFloat(tenderReturnAmount.toFixed(2))
                            );
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name={['vat']} label="VAT">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                    <Form.Item name={['grandtotal']} label="Grand Total">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                    <Form.Item name={['tenderAmount']} label="Tender Amount" hidden>
                      <InputNumber
                        controls={false}
                        min={0}
                        disabled
                        style={{ color: 'black' }}
                        precision={0}
                        onChange={(val) => {
                          const grandTotal = form.getFieldValue(['grandtotal']);
                          if (grandTotal && val) {
                            const tenderReturnAmount = val - grandTotal;
                            form.setFieldValue(
                              ['tenderReturnAmount'],
                              parseFloat(tenderReturnAmount.toFixed(2))
                            );
                          } else {
                            message.warning('Please add amount first');
                            form.setFieldValue(['tenderAmount'], null);
                            form.setFieldValue(['tenderReturnAmount'], null);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name={['tenderReturnAmount']} label="Return" hidden>
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                  </div>
                  <div className="grid sm:grid-cols-1 gap-x-5">
                    <div className="mt-2 grid grid-cols-auto-90 gap-2">
                      <Button
                        type={'text'}
                        className="custom-button"
                        disabled={!hasRule || isLoading || isQRModalOpen}
                        style={{ backgroundColor: '#008000', color: 'white', borderRadius: '5px' }}
                        icon={<AccountBookOutlined style={{ fontSize: '16px' }} />}
                        onClick={async () => {
                          try {
                            await form.validateFields();
                            setPayType('cash');
                            const grandTotal = form.getFieldValue(['grandtotal']);
                            if (grandTotal) {
                              setIsAddTenderModalOpen(true);
                            } else {
                              message.warning('Please add amount first');
                              setPayType('');
                              form.setFieldValue(['tenderAmount'], null);
                              form.setFieldValue(['tenderReturnAmount'], null);
                            }
                          } catch (e) {
                            console.log('error', e);
                          }
                        }}>
                        Cash
                      </Button>
                      <Button
                        type={'text'}
                        className="custom-button"
                        disabled={!hasRule || isLoading || isQRModalOpen}
                        icon={<MobileOutlined style={{ fontSize: '16px' }} />}
                        style={{
                          backgroundColor: '#0F52BA',
                          color: 'white',
                          borderRadius: '5px'
                        }}
                        onClick={async () => {
                          setPayType('epay');
                          await submitForm();
                        }}>
                        Epay
                      </Button>
                      <Button
                        type={'text'}
                        className="custom-button"
                        disabled={!hasRule || isLoading || isQRModalOpen}
                        icon={<CreditCardOutlined style={{ fontSize: '16px' }} />}
                        style={{
                          backgroundColor: '#CC5500',
                          color: 'white',
                          borderRadius: '5px'
                        }}
                        onClick={async () => {
                          setPayType('card');
                          await submitForm();
                        }}>
                        Card
                      </Button>
                      {selectedCustomer?.isCredit && (
                        <Button
                          type={'text'}
                          className="custom-button"
                          disabled={!hasRule || isLoading || isQRModalOpen}
                          icon={<ShopOutlined style={{ fontSize: '16px' }} />}
                          style={{
                            backgroundColor: '#7F00FF',
                            color: 'white',
                            borderRadius: '5px'
                          }}
                          onClick={async () => {
                            try {
                              await form.validateFields();
                              setPayType('credit');
                              setIsloading(true);
                              await submitForm();
                            } catch (error) {
                              setIsloading(false);
                            }
                          }}>
                          Credit
                        </Button>
                      )}
                    </div>
                    <Button
                      type="text"
                      className="mt-2"
                      onClick={onClearForm}
                      style={{
                        backgroundColor: '#818589',
                        color: 'white',
                        borderRadius: '5px',
                        width: '100%'
                      }}>
                      Clear
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
          </Form>
        </Spin>
      </PosLayout>
    </PosSessionProvider>
  );
};

export default POSCreateV2;
